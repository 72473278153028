const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const createTask = async (taskData: any) => {
  try {
    const response = await fetch(`${API_BASE_URL}?url=createTask`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(taskData),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || "Network response was not ok");
    }

    return response.json();
  } catch (error) {
    console.error("Error creating task:", error);
    throw error;
  }
};

export const fetchDealByConnection = async (connectionData: {
  connectionId: number;
}) => {
  try {
    const response = await fetch(`${API_BASE_URL}?url=getDealByConnectionId`, {
      method: "POST", // Changed from GET to POST
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(connectionData),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || "Network response was not ok");
    }

    return response.json();
  } catch (error) {
    console.error("Error fetching deal:", error);
    throw error;
  }
};

export const fetchTasksByDealId = async (dealId: number) => {
  try {
    const response = await fetch(`${API_BASE_URL}?url=getTasksByDealId`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ dealId }),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || "Network response was not ok");
    }

    return response.json();
  } catch (error) {
    console.error("Error fetching tasks:", error);
    throw error;
  }
};

// Mark task as completed
export const completeTask = async (taskData: any) => {
  try {
    const response = await fetch(`${API_BASE_URL}?url=completeTask`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(taskData),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || "Network response was not ok");
    }

    return response.json();
  } catch (error) {
    console.error("Error completing task:", error);
    throw error;
  }
};
