import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import { getMessagesBetweenUsers, sendMessage } from "../../../api/messageApi";
import { useUser } from "../../../context/UserContext";
import { useDashboard } from "../../../context/DashboardContext";

interface Message {
  id: string;
  sender: string;
  content: string;
  timestamp: string;
  from_id: number;
  sender_name?: string;
  recipient_name?: string;
}

const ChatContainer = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  height: 70vh;
  box-sizing: border-box;
`;

const MessageList = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding-right: 10px;
`;

const MessageBubble = styled.div<{ isUser: boolean }>`
  margin-bottom: 15px;
  padding: 10px 15px;
  border-radius: 20px;
  max-width: 70%;
  align-self: ${(props) => (props.isUser ? "flex-end" : "flex-start")};
  background-color: ${(props) => (props.isUser ? "#9d86f4" : "#f4f4f8")};
  color: ${(props) => (props.isUser ? "#ffffff" : "#000000")};
  border: 1px solid ${(props) => (props.isUser ? "#9d86f4" : "#e0e0e0")};
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
`;

const MessageInfo = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
`;

const Sender = styled.span`
  font-weight: bold;
  font-size: 0.85rem;
`;

const Timestamp = styled.span`
  font-size: 0.75rem;
  color: #999999;
`;

const MessageContent = styled.p`
  margin: 0;
  font-size: 1rem;
  line-height: 1.4;
`;

const MessageInputContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: #ffffff;
  border-radius: 22px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`;

const MessageInput = styled.input<{ disabled?: boolean }>`
  border: none;
  outline: none;
  flex-grow: 1;
  font-size: 16px;
  padding: 10px;
  border-radius: 22px;
  &::placeholder {
    color: #c4c4c4;
  }
  ${(props) => props.disabled && "background-color: #f0f0f0;"}
`;

const SendButton = styled.button<{ disabled?: boolean }>`
  background-color: ${(props) => (props.disabled ? "#cccccc" : "#9d86f4")};
  color: #ffffff;
  padding: 10px 20px;
  border: none;
  border-radius: 22px;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  font-size: 16px;
  transition: background-color 0.3s;
  margin-left: 10px;
  &:hover {
    background-color: ${(props) => (props.disabled ? "#cccccc" : "#6a45ae")};
  }
`;

const ChatPage: React.FC = () => {
  const [messages, setMessages] = useState<Message[]>([]);
  const [newMessage, setNewMessage] = useState("");
  const [loading, setLoading] = useState(true);
  const [sending, setSending] = useState(false); // Added state variable
  const [error, setError] = useState<string | null>(null);
  const { user } = useUser();
  const { recipientId } = useDashboard();
  const messageListRef = useRef<HTMLDivElement>(null);

  // Extract message fetching logic into a reusable function
  const fetchMessages = () => {
    if (user && recipientId) {
      getMessagesBetweenUsers(user.user_id, recipientId)
        .then((data: any) => {
          setMessages(data.messages);
          setLoading(false);

          setSending(false);
        })
        .catch((err: any) => {
          setError(err.message);
          setLoading(false);
        });
    }
  };

  // Set up periodic fetching
  useEffect(() => {
    console.log("Recipient ID:", recipientId);
    // Fetch messages immediately on component mount
    fetchMessages();

    // Set up interval to fetch messages every 15 seconds
    const interval = setInterval(() => {
      fetchMessages();
    }, 15000); // 15000 milliseconds = 15 seconds

    // Clean up the interval on component unmount or when dependencies change
    return () => clearInterval(interval);
  }, [user, recipientId]);

  // Auto-scroll to bottom when messages change
  useEffect(() => {
    if (messageListRef.current) {
      messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
    }
  }, [messages]);

  const handleSendMessage = async () => {
    if (newMessage.trim() === "" || sending) return;

    // Clear the input field immediately and set sending state
    setNewMessage("");
    setSending(true);

    try {
      if (!user?.user_id || !recipientId) return;
      const content = newMessage;
      const recipients = [
        { from_id: user.user_id, to_id: recipientId },
        { from_id: recipientId, to_id: user.user_id },
      ];
      const attachments: any[] = []; // Add attachments if needed

      await sendMessage(
        user.user_id,
        content,
        recipients,
        attachments,
        recipientId
      );

      // Fetch messages immediately after sending
      fetchMessages();
    } catch (error) {
      console.error("Error sending message:", error);
      // Optionally, set error state to display an error message
      // setError("Failed to send message. Please try again.");
    } finally {
      // Reset the sending state
      // setSending(false);
    }
  };

  return (
    <ChatContainer>
      <MessageList ref={messageListRef}>
        {messages.map((message) => (
          <MessageBubble
            key={message.id}
            isUser={message.from_id === user?.user_id}
          >
            <MessageInfo>
              <Sender>
                {message.from_id === user?.user_id
                  ? "You"
                  : message.sender_name}
              </Sender>
              <Timestamp>{message.timestamp}</Timestamp>
            </MessageInfo>
            <MessageContent>{message.content}</MessageContent>
          </MessageBubble>
        ))}
        {sending ? "Sending Message..." : null}
      </MessageList>
      <MessageInputContainer>
        <MessageInput
          type="text"
          placeholder="Type something..."
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          onKeyPress={(e) => e.key === "Enter" && handleSendMessage()}
          disabled={sending}
        />
        <SendButton onClick={handleSendMessage} disabled={sending}>
          {sending ? "Sending..." : "Send"}
        </SendButton>
      </MessageInputContainer>
    </ChatContainer>
  );
};

export default ChatPage;
